// helpers
import { useDelete, useFetch, usePost, usePatch, usePut } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_ROUTES from "@/api/routes.constant";

// interfaces
import {
  ILocationFirstStepResponse,
  ILocationSecondStepResponse,
  ILocationThirdStepResponse,
  ISingleLocationResponse
} from "@/types/api/SingleLocationResponse";
import { QueryArgsInterface, IUseMutation } from "@/types/api/queryArgs";
import { IUseQueryResult } from "@/types/api/UseQueryResult";
import { IQuickSearchResult } from "@/types/api/QuickSearchResult";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";
import { IAbstractRespListItem } from "@/types/Global";
import {
  ILocationsResponse,
  IShortLocation,
  ILocationCalendarResponse,
  ILocationMemberResponse,
  ILocationConversion,
  ILocationsCountByStatusResponse,
  ILocationSpaceItem,
} from "@/types/api/Locations";

const { locationsUrls, previewUrls } = API_ROUTES;

type QueryBySlugArgs = {
  slug?: string | null;
  preview?: string;
} & QueryArgsInterface;

const locations = {
  useLocationsSimplifiedList: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<IShortLocation>>(
      locationsUrls.locationsShort,
      params,
      config
    )
  },

  useLocation: ({ slug, preview, params, config = {} }: QueryBySlugArgs) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null;
    const fetchKeyPreview = slug ? pathToUrl(previewUrls.previewLocation, { slug }) : null;

    return useFetch(
      preview ? fetchKeyPreview : fetchKey,
      params,
      { ...config, axiosConfig: { headers: { "LOCATION-AUTH": `Bearer ${preview}` } } }
    ) as IUseQueryResult<ISingleLocationResponse>;
  },

  usePostLocation: () => usePost<ISingleLocationResponse>(locationsUrls.locations),

  usePatchLocation: (slug: string) => usePatch<ISingleLocationResponse>(
    slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null
  ),

  useRefreshAvailability: (slug: string) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.updateAvailability, { slug }) : null;

    return useFetch(fetchKey, {}, { enabled: false });
  },

  useQuickSearch: (args?: QueryArgsInterface | null) => {
    const { params } = args || {};

    return useFetch<IPaginatedResponse<IQuickSearchResult>>(
      params ? locationsUrls.quickSearch : null,
      params
    );
  },

  useLocationCalendar: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationCalendar, { slug }) : null;

    return useFetch<ILocationCalendarResponse[]>(fetchKey, params, config);
  },

  useBulkStatusUpdate: (args?: IUseMutation) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationCalendarBulkStatusUpdate, { slug }) : null;

    return usePatch(fetchKey, params, config);
  },

  useGetLocations: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<IPaginatedResponse<ILocationsResponse>>(locationsUrls.locations, params, config);
  },

  usePublishLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationPublish, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },

  useRestoreLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationRestore, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },

  useArchiveLocation: (args: IUseMutation) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationArchive, { slug }) : null;

    return usePut(fetchKey as string, params, config);
  },
  
  useDeleteLocation: (args: IUseMutation) => {
    const { params } = args;

    return useDelete(locationsUrls.locations, params);
  },

  useLocationMembers: (args: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationMembers, { slug }) : null;

    return useFetch<ILocationMemberResponse>(fetchKey, params, config);
  },

  useLocationConversion: (args: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};
    const fetchKey = slug ? pathToUrl(locationsUrls.locationConversion, { slug }) : null;

    return useFetch<IPaginatedResponse<ILocationConversion>>(fetchKey, params, config);
  },

  usePostLocationPartnerEnquiry: (args?: IUseMutation) => {
    const { params, config } = args || {};

    return usePost(locationsUrls.locationPartnerEnquiry, params, config);
  },

  useLocationCampaignItems: (args?: QueryBySlugArgs & { id: number | string }) => {
    const { slug, id, params, config } = args || {};
    const fetchKey = slug && id ? pathToUrl(locationsUrls.locationCampaignItems, { slug, id }) : null;

    return useFetch<IAbstractRespListItem[]>(fetchKey, params, config);
  },

  usePostLocationNudge: ({ slug, params, config }: IUseMutation) => {
    const fetchKey = slug ? pathToUrl(locationsUrls.locationNudge, { slug }) : null;

    return usePost(fetchKey as string, params, config);
  },

  useUpdateLocation: (args: IUseMutation) => {
    const { slug, params } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationsSingle, { slug }) : null;

    return usePatch<ISingleLocationResponse>(fetchKey, params);
  },

  useGetLocationsCountByStatus: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch<ILocationsCountByStatusResponse>(locationsUrls.locationsCountByStatus, params, config);
  },

  usePostLocationFirstStep: (args?: IUseMutation) => {
    const { params } = args || {};

    return usePost(locationsUrls.locationCreateByFirstStep, params);
  },

  useUpdateLocationFirstStep: (args?: IUseMutation) => {
    const { slug, params } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationFirstStep, { slug }) : null;

    return usePatch(fetchKey, params);
  },

  useGetLocationFirstStep: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationFirstStep, { slug }) : null;

    return useFetch<ILocationFirstStepResponse>(fetchKey, params, config);
  },

  useUpdateLocationSecondStep: (args?: IUseMutation) => {
    const { slug, params } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationSecondStep, { slug }) : null;

    return usePatch(fetchKey, params);
  },

  useGetLocationSecondStep: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationSecondStep, { slug }) : null;

    return useFetch<ILocationSecondStepResponse>(fetchKey, params, config);
  },

  useUpdateLocationThirdStep: (args?: IUseMutation) => {
    const { slug, params } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationThirdStep, { slug }) : null;

    return usePatch(fetchKey, params);
  },

  useGetLocationThirdStep: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationThirdStep, { slug }) : null;

    return useFetch<ILocationThirdStepResponse>(fetchKey, params, config);
  },

  useLocationSpaces: (args?: QueryBySlugArgs) => {
    const { slug, params, config } = args || {};

    const fetchKey = slug ? pathToUrl(locationsUrls.locationSpaces, { slug }) : null;

    return useFetch<IPaginatedResponse<ILocationSpaceItem>>(fetchKey, params, config);
  }
};

export default locations;
